import { Link } from "react-router-dom";
import Social from "../social/Social";
import './Footer.css';

export default function Footer(props) {
    return (
        <div className="Footer">
            <div className="vertical-column-container">
                <div className="column social">
                    <div className="social-cta">Follow us</div>
                    <Social />
                </div>
            </div>
            <div className="vertical-column-container">
                <div className="column contact">
                    <Link to={"/contact-us"}>Contact Mel May Creations</Link>
                </div>
            </div>
            <div className="vertical-column-container">
                <div className="column legal">
                    <Link to={"/legal"}>Legal</Link>
                </div>
            </div>
        </div>
    );
}