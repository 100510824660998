import { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import Logo from "../logo/Logo";
import Footer from "../footer/Footer";
import './Login.css';
import { signInWithEmailAndPassword, signInWithRedirect } from "firebase/auth";
import { FacebookAuthProvider } from "firebase/auth/cordova";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomSpinner from "../customspinner/CustomSpinner";


export default function Login(props) {
    const { firebaseApp, firebaseUserAuth } = props;
    const [ loginEmail, setLoginEmail ] = useState('');
    const [ loginPassword, setLoginPassword ] = useState('');
    const [ searchParams,  ] = useSearchParams();
    const [ loginError, setLoginError ] = useState('');
    const [ loginInProgress, setLoginInProgress ] = useState(false);
    const navigate = useNavigate();

    const doFacebookAuth = () => {
        if(firebaseApp && firebaseUserAuth) {
            setLoginInProgress(true);
            signInWithRedirect(firebaseUserAuth, new FacebookAuthProvider())
                .then(() => {
                    const login_request_origin = searchParams && searchParams.get("login_request_origin") ? searchParams.get("login_request_origin") : '/';
                    navigate(login_request_origin);
                })
                .catch((err) => {
                    console.log("Login Error: %o", err);
                    setLoginError("Your login failed.");
                    setLoginEmail('');
                    setLoginPassword('');
                })
                .finally(() => {
                    setLoginInProgress(false);
                });
        }
        else {
            alert('Authorisation plugin not initialized.');
        }
    }

    const doEmailAuth = () => {
        if(firebaseApp && firebaseUserAuth) {
            setLoginInProgress(true);
            signInWithEmailAndPassword(firebaseUserAuth, loginEmail, loginPassword).then(() => {
                console.log("SUCCESS");
                const login_request_origin = searchParams && searchParams.get("login_request_origin") ? searchParams.get("login_request_origin") : '/';
                navigate(login_request_origin);
            })
            .catch((err) => {
                console.log("Login Error: %o", err);
                setLoginError("Your login failed. Please check your email and password and try again.");
                setLoginPassword('');
            })
            .finally(() => {
                setLoginInProgress(false);
            });
        }
        else {
            alert('Authorisation plugin not initialized.');
        }
    }

    const content = loginInProgress === true ? (
        <Fragment>
            <Container className='content-pane' fluid>
            <Row>
                <Col xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <Logo />
                </Col>
            </Row>
            <Row>
                <Col className="field-container facebook-button" xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <div className="login-in-progress-spinner">
                        <CustomSpinner />
                    </div>
                </Col>
            </Row>
            </Container>
        <Footer />
        </Fragment>
    ) : (
            <Fragment>
            <Container className='content-pane' fluid>
            <Row>
                <Col xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <Logo />
                </Col>
            </Row>
            <Row>
                <Col className="field-container facebook-button" xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <div className="facebook-login">
                        <img onClick={doFacebookAuth} src="/img/facebook-login-button.png" alt="facebook login button"></img>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="or-separator" xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <div class="or-label">or</div>
                </Col>
            </Row>
            <Row>
                <Col className="field-container" xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <Label>Email address</Label>
                    <Input id="login-email" name="email" type="email" value={loginEmail} onChange={e => setLoginEmail(e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col className="field-container" xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <Label>Password</Label>
                    <Input id="login-password" name="password" type="password" value={loginPassword} onChange={e => setLoginPassword(e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col className="field-container" xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <div className="login-error">{ loginError ? loginError : ' ' }</div>
                </Col>
            </Row>
            <Row>
                <Col className="field-container button" xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <Button disabled={!loginEmail || !loginPassword} onClick={doEmailAuth} color="light">Login</Button>
                </Col>
            </Row>
            </Container>
        <Footer />
        </Fragment>
    );


    return (
        <div className='Login'>
            { content }
		</div>
    );
}