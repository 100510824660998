import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home/Home';
import Legal from './legal/Legal';
import Lookbook from './lookbook/lookbook';
import PostEdit from './lookbook/postedit/PostEdit';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Login from './login/Login';
import { browserLocalPersistence, connectAuthEmulator, initializeAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDCz-uad6ol4FIiU0__3gmQRMAcMq4HwbE",
    authDomain: "melmay.store",
    projectId: "mel-may-creation",
    storageBucket: "mel-may-creation.appspot.com",
    messagingSenderId: "1011294930563",
    appId: "1:1011294930563:web:22ba539431b9e38c177db6",
    measurementId: "G-DJW9JJTDLQ"
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseUserAuth =  initializeAuth(firebaseApp, { persistence: browserLocalPersistence });
if(window && window.location && window.location.hostname === 'localhost') connectAuthEmulator(firebaseUserAuth, 'http://localhost:9099');
const firebaseAnalytics = getAnalytics(firebaseApp);

function App() {
    const [ firebaseCurrentUser, setFirebaseCurrentUser ] = useState(null);

    useEffect(() => {
        firebaseUserAuth.onAuthStateChanged( user => {
            setFirebaseCurrentUser(user);
            user.getIdTokenResult().then(claims => console.log("is CMS user: %o", claims.claims.cmsUser));
        } );
    });

    console.log("USER: %o", firebaseCurrentUser);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home firebaseAnalytics={firebaseAnalytics} firebaseApp={firebaseApp} firebaseUserAuth={firebaseUserAuth} firebaseCurrentUser={firebaseCurrentUser} />} />
                <Route path="/legal" element={<Legal firebaseAnalytics={firebaseAnalytics} firebaseApp={firebaseApp} firebaseUserAuth={firebaseUserAuth} firebaseCurrentUser={firebaseCurrentUser} />} />
                <Route path="/lookbook" element={<Lookbook firebaseAnalytics={firebaseAnalytics} firebaseApp={firebaseApp} firebaseUserAuth={firebaseUserAuth} firebaseCurrentUser={firebaseCurrentUser} />} />
                <Route path="/lookbook/:postId" element={<PostEdit firebaseAnalytics={firebaseAnalytics} firebaseApp={firebaseApp} firebaseUserAuth={firebaseUserAuth} firebaseCurrentUser={firebaseCurrentUser} />} />
                <Route path='/login' element={<Login firebaseAnalytics={firebaseAnalytics} firebaseApp={firebaseApp} firebaseUserAuth={firebaseUserAuth} firebaseCurrentUser={firebaseCurrentUser} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;