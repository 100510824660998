import './Logo.css';

export default function Logo() {
    return (
        <div className='brand-container'>
            <div className="logo-container"></div>
			<div className='logo-title-container'>
                <div className='logo-title-text'>Ouma Skapie</div>
            </div>
        </div>
    );
}
