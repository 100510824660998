import { DotLoader } from 'react-spinners';
import './CustomSpinner.css';

export default function CustomSpinner(props) {
    return (
        <div className='CustomSpinner'>
            <div className="spinner">
                <div><DotLoader /></div>
            </div>
            { props && props.message ? <div className='spinner-message'>{props.message}</div> : null }
        </div>
    );
}