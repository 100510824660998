import './Social.css';

export default function Social() {
    return (
        <div className="Social">
            <div className='icons'>
                <div className="social-icon social-icon-facebook">
                    <a href="https://www.facebook.com/profile.php?id=100095141553142" target="_blank" rel="noreferrer">
                        <img src="/img/Facebook_Logo_Primary.png" alt="Facebook Icon"></img>
                    </a>
                </div>
                <div className="social-icon social-icon-instagram">
                    <a href="https://www.instagram.com/ouma_skapie/" target="_blank" rel="noreferrer">
                        <img src="/img/Instagram_Glyph_Gradient.png" alt="Instagram Icon"></img>
                    </a>
                </div>
            </div>
        </div>
    );
}