import { useEffect, useState } from "react";

function ImageItem(_key, media_url, updateImageData) {
    const imageResample = (e) => {
        const el = e.target;
        let curSib = el.nextElementSibling;
        while(true) {
            if(!curSib) break;
            if(!curSib.classList || curSib.classList.length !== 2 || curSib.classList[0] !== "canvas-tag") {
                console.error("Invalid <img> sibling. Must be <canvas class='canvasTag... ");
                break;
            }
            const widthClass = curSib.classList[1];
            const requiredWidth = parseInt(widthClass.substring("canvas-".length));
            const actualWidth = el.getBoundingClientRect().width;
            const actualHeight = el.getBoundingClientRect().height;
            const ratio = requiredWidth / actualWidth;
            const requiredHeight = actualHeight * ratio;
            curSib.width = requiredWidth;
            curSib.height = requiredHeight;
            const context = curSib.getContext("2d");
            context.drawImage(el, 0, 0, requiredWidth, requiredHeight);
            const dataUrl = curSib.toDataURL();
            updateImageData(dataUrl);
            curSib = curSib.nextElementSibling;
        }
    };
    return (
        <div key={_key} style={{overflow: "scroll"}}>
            <img className="image-tag" src={media_url} onLoad={imageResample} style={{display: "block"}} crossOrigin="anonymous" alt='post resized'></img>
            <canvas className="canvas-tag canvas-300" style={{display: "block"}}></canvas>
            <canvas className="canvas-tag canvas-600" style={{display: "block"}}></canvas>
            <canvas className="canvas-tag canvas-900" style={{display: "block"}}></canvas>
            <canvas className="canvas-tag canvas-993" style={{display: "block"}}></canvas>
        </div>
    );
}

export default function ImageTin(props) {
    const [ imageData, setImageData ] = useState([]);
    const [ imageUrls, setImageUrls ] = useState([]);
    const { imageDataCallback } = props;
    const updateImageData = (u) => {
        imageData.push(u);
        setImageData(imageData);
        if(imageDataCallback) imageDataCallback(imageData);
    }
    useEffect(() => {
        const mediaUrls = [];
        if(props.post.media_url) mediaUrls.push(props.post.media_url);
        if(props.post.children && props.post.children.data && props.post.children.data.length) {
            props.post.children.data.forEach(element => { mediaUrls.push(element.media_url) });
        }
        setImageUrls(mediaUrls);
    })
    return (
        <div className="image-tin">
            { imageUrls.map( iu => ImageItem(iu, iu, updateImageData) ) }
        </div>
    );
}