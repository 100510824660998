function firebasePropsFilter(object) {
    if(!object) {
        return {};
    }
    const result = {};
    Object.keys(object).forEach(k => {
        if(k.startsWith('firebase')) {
            result[k] = object[k];
        }
    });
    return result;
}

export { firebasePropsFilter };