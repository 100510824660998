import { Collapse, Nav, Navbar, NavItem, NavLink, NavbarBrand, NavbarToggler, Button } from 'reactstrap';
import './NavigationBar.css';
import Logo from '../logo/Logo';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function NavigationBar(props) {
    const { firebaseUserAuth, firebaseCurrentUser } = props;
    const location = useLocation();
    const [ open, setOpen ] = useState(false);
	let className = "NavigationBar navbar-light";

    const navbarProps = {};
	if(props && props.fixed) {
		className = className + " fixed-top";
        navbarProps.expand = 'md';
	}
    else {
        navbarProps.expand = 'xs';
    }

    const doLogout = () => {
        if(firebaseUserAuth) {
            firebaseUserAuth.signOut()
                .then(() => { 
                    // noop
                })
                .catch( err => { 
                    console.log("Logout failed: %o", err) ;
                });
        }
    }
  
    return (
        <Navbar className={ className } {... navbarProps}>
            <NavbarBrand href='/'>
                <Logo />
            </NavbarBrand>
            <NavbarToggler className='d-md-none d-xs-block' onClick={ () => setOpen(!open) } />
            <Collapse isOpen={open} navbar>
                <Nav navbar>
                    <NavItem>
                        <NavLink className="navbar-link-item" tag={Link} to="/lookbook">Lookbook</NavLink>
                    </NavItem>
                </Nav>
                <Nav style={{marginLeft: 'auto'}}>
                    { !firebaseCurrentUser ? (
                            <NavItem>
                                <NavLink className="navbar-link-item" tag={Link} to={`/login?login_request_origin=${location.pathname}`}>Login</NavLink>
                            </NavItem>
                        ) : (
                            <NavItem>
                                <Button className="logout navbar-link-item" color="link" onClick={doLogout}>Logout</Button>
                            </NavItem>
                        )
                    }
                </Nav>
            </Collapse>
        </Navbar>
    );
}
