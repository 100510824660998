import { Col, Container, Row } from "reactstrap";
import Logo from "../logo/Logo";
import Footer from "../footer/Footer";
import NavigationBar from "../navbar/NavigationBar.js";
import './Home.css';
import { firebasePropsFilter } from "../util/Util.js";

export default function Home(props) {
    return (
        <div className='Home'>
            <Container className='content-pane' fluid>
            <Row>
                <Col xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <Logo />
                </Col>
            </Row>
			<Row>
				<Col xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
					<NavigationBar {...firebasePropsFilter(props)} />
				</Col>
			</Row>
            <Row>
                <Col xs={{size: 10, offset: 1}} sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <div className="introduction">
                        Welcome to Ouma Skapie baby boutique, where every piece tells a unique story of love and care for your little one. 
                        Nestled in the heart of Touwsrivier, our boutique specializes in crafting bespoke baby wear and nursery decor that 
                        reflect the individuality and charm of each precious baby. From soft, hand-stitched clothing to enchanting nursery accents, 
                        we pride ourselves on offering exquisite designs that celebrate the joy of parenthood. Whether you're searching for the perfect 
                        outfit for your newborn or seeking timeless decor to adorn their nursery, our dedicated team is here to ensure your experience is 
                        nothing short of magical. Discover the beauty and uniqueness of made by Ouma Skapie, where every detail is crafted with love and 
                        passion for your little bundle of joy.
					</div>
                </Col>
            </Row>
			</Container>
			<Footer />
		</div>
    );
}
