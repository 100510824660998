import { Carousel } from 'nuka-carousel';
import './PostImages.css';

const setWidth = (e) => { e.target.width = e.target.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect().width; };

export default function PostImages(props) {
    const { postObject } = props;
    return (
        <div className="pictures">
            { postObject.children && postObject.children.data.length ? (<Carousel showDots={false} showArrows={true}>
                <div className="image-container">
                    <img src={postObject.media_url} alt={"Post primary"} onLoad={setWidth}></img>
                </div>
                { postObject.children.data.map( (c, n) => <div className="image-container"><img src={c.media_url} alt={"Post child #" + (n + 1)} onLoad={setWidth}></img></div> ) }
            </Carousel>) : <img src={postObject.media_url} alt={"Post primary"} style={{ width: "100%" }}></img> }
        </div>);
}