import { Fragment, useEffect, useState } from "react";
import superagent from 'superagent';
import PostImages from "../postimages/PostImages";
import { Container, Label, FormGroup, Input, Button } from 'reactstrap';
import NavigationBar from "../../navbar/NavigationBar";
import Footer from "../../footer/Footer";
import { useNavigate, useParams } from "react-router-dom/dist";
import './PostEdit.css';
import CustomSpinner from "../../customspinner/CustomSpinner";
import ImageTin from "./imagetin/ImageTin";
import { Progress } from "reactstrap";
import { firebasePropsFilter } from "../../util/Util";

function getPost(postId, setFetching, setFailure, updatePost) {
    setFetching(true);
    superagent.get(`/api/instagram/post/${postId}`)
        .then( rsp => {
            updatePost(rsp.body);
        })
        .catch(err => {
            setFailure(`Failed to get data from API: (${err.status} ${err.text}) `);
        })
        .finally(() => {
            setFetching(false);
        });
}

function createPost(postObject, setSending, setFailure, postComplete) {
    setSending(true);
    superagent.post(`/api/website/post`)
        .send(postObject)
        .then((rsp) => {
            postComplete();
        })
        .catch(err => {
            setFailure("Failed to create website post: " + err.status);
        })
        .finally(() => {
            setSending(false);
        });
}

function getPostUpdater(setTitle, setCaption, setPostObject) {
    return (post) => {
        const webTitle = post.webTitle ? post.webTitle : '';
        setTitle(webTitle);
        const caption = post.webCaption ? post.webCaption : post.caption ? post.caption : '';
        setCaption(caption);
        setPostObject(post);
    };
}

export default function PostEdit(props) {
    const params = useParams();
    
    const [ postId, setPostId ] = useState(params.postId);
    const [ fetching, setFetching ] = useState(true);
    const [ failure, setFailure ] = useState(null);

    const [ title, setTitle ] = useState('');
    const [ caption, setCaption ] = useState('');
    const [ postObject, setPostObject ] = useState(null);
    const [ scaledImages, setScaledImages ] = useState([]);

    const navigate = useNavigate();
    const postComplete = () => {
        navigate("/lookbook");
    }

    let imageCount = 0;
    let imageProgressPerc = 0;
    if(postObject) {
        imageCount = ((postObject.media_url ? 1 : 0) + 
                    (postObject.children && postObject.children.data && postObject.children.data.length ? postObject.children.data.length : 0)) * 4;
        imageProgressPerc = 100 * (scaledImages.length / imageCount);
    }

    useEffect(() => {
        const _postId = params.postId;;
        setPostId(_postId);    
        getPost(_postId, setFetching, setFailure, getPostUpdater(setTitle, setCaption, setPostObject));
    }, [ params.postId ]);

    const titleComplete = title && title.trim() !== '';
    const captionComplete = caption && caption.trim() !== '';
    const formComplete = titleComplete && captionComplete;

    const doCreatePost = () => {
        postObject.webTitle = title;
        postObject.webCaption = caption;
        postObject.webUrls = scaledImages;
        createPost(postObject, setFetching, setFailure, postComplete);
    };
    
    let titleClasses = "title";
    if(!titleComplete) {
        titleClasses = titleClasses + " is-invalid";
    }

    let captionClasses = "caption";
    if(!captionComplete) {
        captionClasses = captionClasses + " is-invalid";
    }

    const buttonProperties = {};
    if(!formComplete) {
        buttonProperties.disabled = true;
    }

    const onImage = (x) => {
        setScaledImages(x);
    }

    const content = fetching ? 
        (<div className="post-edit-content fetching">
            <div className='loading-container'>
                <CustomSpinner message={`Getting post #${postId}`}/>
            </div>
        </div>
        ) : failure ? (
            <div className="post-edit-content failure">
                <div className="title">Failed to get post {postId}</div>
                <div className="message">{failure}</div>
            </div>
        ) : postObject ? (
            <div className="post-edit-content form">
                <div className="post-form">
                    <FormGroup>
                        <Label>Post title</Label>
                        <Input className={titleClasses} type="text" value={title} onChange={e => setTitle(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Post caption</Label>
                        <Input className={captionClasses} type="textarea" value={caption} onChange={e => setCaption(e.target.value)} />
                    </FormGroup>
                    { postObject ? <Progress value={imageProgressPerc}>{ scaledImages.length + " / " + imageCount + " images resized" }</Progress> : null }
                    <PostImages postObject={postObject} />
                    <div className="button-actions">
                        <Button className="save-action" color="light" {...buttonProperties} onClick={doCreatePost}>Save</Button>
                    </div>
                </div>
            </div>
        ) : <div>Something has gone wrong. Please contact support.</div>;
    

    return (
        <Fragment>
            <div className="PostEdit">
                <NavigationBar fixed={true} {...firebasePropsFilter(props)} />
                <Container className='bootstrap-container' fluid>
                    { content }               
                </Container>
                <Footer />
            </div>
            { postObject ? <ImageTin post={postObject} imageDataCallback={onImage} /> : null }
        </Fragment>
    );

}