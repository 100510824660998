import { Container, Button, Form, Input } from "reactstrap";
import NavigationBar from "../navbar/NavigationBar";
import superagent from 'superagent';
import { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import './Lookbook.css';
import { ReactComponent as PostIcon } from './fontawesome_box_add.svg';
import { ReactComponent as RemoveIcon } from './fontawesome_close_icon.svg';
import { ReactComponent as EditIcon } from './fontawesome_edit_square_icon.svg';
import { Link } from "react-router-dom/dist";
import PostImages from "./postimages/PostImages";
import CustomSpinner from "../customspinner/CustomSpinner";
import { firebasePropsFilter } from "../util/Util";
import InfiniteScroll from "react-infinite-scroll-component";

function fetchPosts (firebaseCurrentUser, posts, setPosts, hasMore, setHasMore, index, setIndex, setFailure) {
    if(!firebaseCurrentUser || !firebaseCurrentUser.accessToken) {
        console.log("Could not determine api key from %o", firebaseCurrentUser);
        return;
    }
    superagent.get("/api/instagram/post")
        .query(index && index !== '' ? { after: index } : {})
        .auth(firebaseCurrentUser.accessToken, { type: 'bearer' })
        .then( rsp => {
            if(rsp.status === 204 || !rsp.body || !rsp.body.posts || !rsp.body.posts.length) {
                setHasMore(false);
                setIndex(null);
                return;
            }
            const curPosts = posts.slice();
            curPosts.splice(curPosts.length, 0, ...rsp.body.posts);
            setPosts(curPosts);
            setHasMore(!!rsp.body.nextPage);
            setIndex(rsp.body.nextPage);
        } )
        .catch( err => {
            setFailure(`Failed to get data from Instagram API: (${err.status} ${err.text}) `);
        } );
}

function Post(props) {
    const { postObject, firebaseUser } = props;
    return (
        <div className="post" key={postObject.id}>
            {firebaseUser ? (<div className="admin-bar">
                <div className="left-group">
                    { postObject.web ? <Form method="post" action={`/api/website/post/${postObject.id}`}>
                        <Input type="hidden" name="action" value={`delete: ${postObject.id}`} />
                        <Button type='submit' className="admin-button remove-action" color='light'><RemoveIcon /></Button> 
                    </Form> : null }
                </div>
                <div className="right-group">
                    { !postObject.web ? <Button className="admin-button post-action" color='light' tag={Link} to={`/lookbook/${postObject.id}`}><PostIcon /></Button> : null }
                    { postObject.web ? <Button className="admin-button edit-action" color='light' tag={Link} to={`/lookbook/${postObject.id}`}><EditIcon /></Button> : null }
                </div>
            </div>) : null }
            <div className="post-content">
                <div className="id post-title">{postObject.webTitle ? postObject.webTitle : postObject.id}</div>
                <div className="post-caption">{postObject.webCaption ? postObject.webCaption : postObject.caption}</div>
                <PostImages postObject={postObject} />
            </div>
        </div>
    );
}

export default function Lookbook(props) {
    const { firebaseCurrentUser } = props;
    const [ posts, setPosts ] = useState([]);
    const [ hasMore, setHasMore ] = useState(true);
    const [ index, setIndex ] = useState(null);
    const [ failure, setFailure ] = useState(null);

    useEffect(() => {
        fetchPosts(firebaseCurrentUser, posts, setPosts, hasMore, setHasMore, index, setIndex, setFailure);
    }, [ firebaseCurrentUser ] );

    const content = failure ? (
        <div className="lookbook-content failure">
            <div className="title">Failed to fetch posts</div>
            <div className="message">{failure}</div>
        </div>
    ) : (
        <InfiniteScroll
            dataLength={posts.length}
            next={() => fetchPosts(firebaseCurrentUser, posts, setPosts, hasMore, setHasMore, index, setIndex, setFailure)}
            hasMore={hasMore}
            loader={<CustomSpinner message={'fetching posts'} />}>
            <div className="lookbook-content post-container">
                { posts 
                    && posts.length > 0 && posts.map( p => <Post postObject={p} firebaseUser={firebaseCurrentUser} />) }
            </div>
        </InfiniteScroll>
    );
    return (
        <div className="Lookbook">
            <NavigationBar fixed={true} {...firebasePropsFilter(props)} />
            <Container className='content-pane' fluid>
                { content }
            </Container>
            <Footer {...firebasePropsFilter(props)} />
        </div>
    );
}
